import HomeSelect from "./Components/HomeSelect";
import HomeAbout from "./Components/homeAbout";
import HomeFirst from "./Components/homeFirst";
import HomeKitchen from "./Components/homeKitchen";
import HomeMenu from "./Components/homeMenu";
import HomeOpinion from "./Components/homeOpinion";
import HomeOurMenu from "./Components/homeOurMenu";
import HomeTips from "./Components/homeTips";
import HomeVideo from "./Components/homeVideo";

export default function Home() {
    return(
        <>
            <HomeFirst />
            <HomeAbout />
            <HomeOpinion />
            <HomeKitchen />
            <HomeMenu />
            <HomeTips />
            <HomeVideo />
            <HomeOurMenu />
            <HomeSelect />
        </>
    )
}